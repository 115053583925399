import React, { Component } from "react";

function VitrineMiddle(){

    return(
        <div className="container">
            <div chaordic="middle"></div>
        </div>

    )
}
export default VitrineMiddle;