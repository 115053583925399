import item1 from '../img/marcas/puma.svg';
import item2 from '../img/marcas/coca-cola.svg';
import item3 from '../img/marcas/fila.svg';
import item4 from '../img/marcas/umbro.svg';
import item5 from '../img/marcas/brazilline.svg';
import item6 from '../img/marcas/oxn.svg';
import item7 from '../img/marcas/olympikus.svg';
import item8 from '../img/marcas/redley.svg';
import item9 from '../img/marcas/kenner.svg';
import item10 from '../img/marcas/mizuno.svg';
import item11 from '../img/marcas/oakley.svg';
import item12 from '../img/marcas/retromania.svg';
import item13 from '../img/marcas/nicoboco.svg';
import item14 from '../img/marcas/underarmor.svg';
import item15 from '../img/marcas/approve.svg';
import item16 from '../img/marcas/colcci.svg';
import item17 from '../img/marcas/diversao.svg';
import item18 from '../img/marcas/hangloose.svg';
import item19 from '../img/marcas/hurley.svg';
import item20 from '../img/marcas/new-era.svg';
import item22 from '../img/marcas/betel.svg';
import item23 from '../img/marcas/surf-center.svg';
import item24 from '../img/marcas/kappa.svg';
import item25 from '../img/marcas/rebook.svg';
import item26 from '../img/marcas/klin.svg';
import item27 from '../img/marcas/cyber/puma.svg';
import item28 from '../img/marcas/cyber/coca-cola.svg';
import item29 from '../img/marcas/cyber/fila.svg';
import item30 from '../img/marcas/cyber/umbro.svg';
import item31 from '../img/marcas/cyber/brazilline.svg';
import item32 from '../img/marcas/cyber/oxn.svg';
import item33 from '../img/marcas/cyber/olympikus.svg';
import item34 from '../img/marcas/cyber/redley.svg';
import item35 from '../img/marcas/cyber/kenner.svg';
import item36 from '../img/marcas/cyber/mizuno.svg';
import item37 from '../img/marcas/cyber/oakley.svg';
import item38 from '../img/marcas/cyber/retromania.svg';
import item39 from '../img/marcas/cyber/nicoboco.svg';
import item40 from '../img/marcas/cyber/underarmor.svg';
import item41 from '../img/marcas/cyber/approve.svg';
import item42 from '../img/marcas/cyber/colcci.svg';
import item43 from '../img/marcas/cyber/diversao.svg';
import item44 from '../img/marcas/cyber/hangloose.svg';
import item45 from '../img/marcas/cyber/hurley.svg';
import item46 from '../img/marcas/cyber/new-era.svg';
import item47 from '../img/marcas/cyber/betel.svg';
import item48 from '../img/marcas/cyber/surf-center.svg';
import item49 from '../img/marcas/cyber/kappa.svg';
import item50 from '../img/marcas/cyber/rebook.svg';
import item51 from '../img/marcas/cyber/klin.svg';
export default  {
    item1,
    item2,
    item3, 
    item4, 
    item5, 
    item6, 
    item7, 
    item8, 
    item9, 
    item10,
    item11,
    item12,
    item13,
    item14,
    item15,
    item16,
    item17,
    item18,
    item19,
    item20,
    item22,
    item23,
    item24,
    item25,
    item26,
    item27,
    item28,
    item29,
    item30,
    item31,
    item32,
    item33,
    item34,
    item35,
    item36,
    item37,
    item38,
    item39,
    item40,
    item41,
    item42,
    item43,
    item44,
    item45,
    item46,
    item47,
    item48,
    item49,
    item50,
    item51,
  };
